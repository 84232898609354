<template>
    <div>
        <div class="d-flex">
            <span
                :class="`mr-3 d-block h4 collapse-button ${isCollapsed ? 'collapsed' : ''}`"
                @click="collapse"
            >
                >
            </span>
            <slot name="title" />
        </div>

        <div
            ref="content"
            class="content"
        >
            <slot name="content" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isCollapsed: false
        }
    },

    created() {
        window.addEventListener('resize', this.resize);
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.resize);
    },

    updated() {
        this.resize();
    },

    methods: {
        resize() {
            const elem = this.$refs.content;

            //Update the content max-height for dynamic DOM changes
            if (!this.isCollapsed)
                elem.style.maxHeight = elem.scrollHeight + 'px';
        },

        collapse(ev) {
            ev.preventDefault();
            ev.stopPropagation();

            const elem = this.$refs.content
            elem.style.maxHeight = this.isCollapsed ? elem.scrollHeight + 'px' : '0px';

            this.isCollapsed = !this.isCollapsed;
        }
    }
}
</script>

<style scoped>
.collapse-button {
    cursor: pointer;
}

.collapse-button::selection {
    background-color: transparent;
}

.collapse-button {
    transform: rotate(90deg);
    transition: transform ease-out .1s;
}

.collapse-button.collapsed {
    transform: rotate(0deg);
}

.content {
    overflow: hidden;
    transition: max-height ease-in-out .3s;
}
</style>