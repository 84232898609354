<template>
    <label class="switch">
        <input
            v-model="localValue"
            type="checkbox"
        >
        <span class="slider round" />
    </label>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: Boolean,
            required: false,
            default: () => false
        },
    },

    emits: ['update:modelValue'],

    data() {
        return {
            localValue: this.modelValue == '' ? this.value : this.modelValue
        }
    },

    watch: {
        localValue(newVal) {
            this.$emit('update:modelValue', newVal);
        },

        modelValue(newVal) {
            this.localValue = newVal;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../_custom.scss';



/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: map-get($theme-colors, 'primary');
}

input:focus + .slider {
  box-shadow: 0 0 1px map-get($theme-colors, 'primary');
}

input:checked + .slider:before {
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
